<template>
  <div class="container mt-4">
    <PageTitle title="Preisliste" subtitle="PureMe Kosmetikinstitut"></PageTitle>

    <button @click="save" class="btn btn-success">Speichern</button>

    <SlickList class="mt-3 category-list" axis="y" v-model:list="categories">
      <SlickItem class="noselect card p-4 mt-3" v-for="(s, i) in categories" v-bind:key="i" :index="i">
        <div class="d-flex">
          <Editable class="h4 title text-primary fw-bold flex-grow-1" v-model="s.title"></Editable>
          <button class="btn btn-sm text-primary bi bi-trash" @click="deleteCategory(i)">
          </button>
        </div>

        <hr>
        <p>{{s.prices.length }} Einträge</p>
        <div v-if="false">
          <SlickList axis="y" v-model:list="s.prices">

            <SlickItem v-for="(c, ci) in s.prices" v-bind:key="ci" :index="ci" class="d-flex flex-column mb-2">
              <div class="d-flex">
                <Editable placeholder="Titel/Name" v-model="c.title" class="fw-bold flex-grow-1"></Editable>
                <Editable placeholder="Preis" v-model="c.priceText" style="width: 5rem"
                          class="fw-bold text-nowrap"></Editable>
                <button class="ms-2 btn btn-sm text-primary bi bi-trash" @click="deletePrice(i, ci)"></button>

              </div>
              <Editable placeholder="Beschreibung" type="textarea" v-model="c.description"></Editable>
              <Editable placeholder="Untertitel" v-model="c.subtitle" class="mt-2"></Editable>

              <hr>
            </SlickItem>


            <div class="card p-4">
              <div class="d-flex">
                <Editable :placeholder="'Titel/Name'" v-model="newPrice.title" class="fw-bold"></Editable>
                <div class="flex-grow-1"></div>
                <Editable :placeholder="'Preis'" v-model="newPrice.priceText" class="fw-bold text-nowrap"></Editable>
              </div>
              <Editable type="textarea" :placeholder="'Beschreibung'" v-model="newPrice.description"></Editable>
              <Editable :placeholder="'Untertitel'" v-model="newPrice.subtitle" class="mt-2"></Editable>
              <div>
                <button v-if="newPrice.title?.length > 0" @click="addPrice(s)" class="mt-4 btn btn-primary">
                  Preis hinzufügen
                </button>
              </div>
            </div>
          </SlickList>
        </div>
      </SlickItem>
    </SlickList>

    <div class="noselect card mt-4 p-4">

      <Editable class="h4 title text-primary fw-bold" placeholder="Neue Kategorie" v-model="newCategory"
                type="text"></Editable>

      <div>
        <button v-if="newCategory.length > 0" @click="addCategory" class="mt-4 btn btn-primary">Kategorie
          hinzufügen
        </button>
      </div>

    </div>

  </div>

</template>

<script>
import {SlickList, SlickItem} from 'vue-slicksort';
import PriceListService from "@/service/priceList.service";
import Editable from "@/components/Editable";
import PageTitle from "@/components/PageTitle";

export default {
  name: "PriceList",
  components: {
    PageTitle,
    Editable,
    SlickList,
    SlickItem,
  },
  data() {
    return {
      editMode: false,
      categories: [],

      newCategory: '',
      newPrice: {
        title: '',
        description: '',
        subtitle: '',
        priceText: ''
      }
    }
  },

  mounted() {
    PriceListService.getPriceList()
        .then(data => this.categories = data.categories);
  },

  methods: {
    save() {
      PriceListService.updatePriceList({categories: this.categories})
          .then(data => {
            this.categories = data.categories
            this.editMode = false;
            alert("Gespeichert")
          })
          .catch(() => {
            alert("Fehler beim Speichern!")
          })
    },

    addCategory() {
      this.categories.push({
        title: this.newCategory,
        prices: []
      })
      this.newCategory = '';
    },

    addPrice(category) {
      category.prices.push({
        title: this.newPrice.title,
        description: this.newPrice.description,
        subtitle: this.newPrice.subtitle,
        priceText: this.newPrice.priceText
      })

      this.newPrice = {};
    },

    deleteCategory(i) {
      this.categories.splice(i, 1);
    },

    deletePrice(categoryIndex, priceIndex) {
      this.categories[categoryIndex].prices.splice(priceIndex, 1);
    }
  },

}
</script>

<style scoped>
.category-list {
  max-height: 800px;
  overflow: auto;
}
</style>