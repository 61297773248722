<template>
  <div>
    <template v-if="type === 'textarea'">
      <textarea :placeholder="placeholder" class="transparent-input" :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"></textarea>
    </template>
    <template v-else>
      <input :placeholder="placeholder" class="transparent-input" :value="modelValue"
             @input="$emit('update:modelValue', $event.target.value)">
    </template>
  </div>
</template>

<script>
export default {
  name: "Editable",

  props: ['modelValue', 'placeholder', 'type']
}
</script>

<style scoped>

.transparent-input {
  border: inherit;
  color: inherit;
  background: inherit;
  font-family: inherit;
  font-weight: inherit;
  width: 100%;
}

.transparent-input:hover {
  outline: 1px solid #ddd;
}

.transparent-input:focus, .transparent-input:focus-visible {

  outline: 1px solid #ddd;
}

</style>