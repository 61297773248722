import {getAxios} from "@/store/api";

class PriceListService {
    getPriceList() {
        return getAxios()
            .get("/price-list")
            .then(response => {

                return response.data
            })
    }

    updatePriceList(priceList) {
        return getAxios()
            .put("/price-list", priceList)
            .then(response => {

                return response.data
            })
    }
}

export default new PriceListService();