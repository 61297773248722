<template>
  <div class="row">
    <div class="col">
      <section class="jumbotron text-center">
        <div class="container-fluid">
          <h2 class="jumbotron-heading">{{ title }}</h2>
          <p class="lead text-muted">{{ subtitle }}</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitle",

  props: ["title", "subtitle"]
}
</script>

<style scoped>

</style>